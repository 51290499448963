import React from "react"
import { Link } from "gatsby"
import {graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
import container3 from "../images/container3.jpg"


const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query Images {
      image: file(relativePath: {eq: "container1.jpg"})
      {
        id
        childImageSharp{
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <Layout>
      <Head title="About" />
      {/*<Img 
        fluid={data.image.childImageSharp.fluid}
      />*/}
      <h1>ABOUT US</h1>
      <h2>Quality First</h2>
      <img class="image" src={container3} alt="Container3" />
      <p>Here at Qualiden of Brazil, we have dedicated ourselves since 1992 to supply products with the highest quality for the lowest possible price. Our aim is to provide exceptional service, always exceeding our customers expectations. </p>
      <p>We are proud to say that we are seen by our clients as one of the best supplying companies in Brazil. Our reputation stems from continuous effort to improve our range of products and services.</p>
      
      
      <p> Browse through our site to obtain more information and feel free to   <Link to="/contact">contact us</Link> if you have any questions.
      </p>
    </Layout>
  )
}
export default AboutPage
